import axios from "axios";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";

/** Classe base que fará a comunicação com o backend.
 * Esta classe trata as configurações de headers, paths e todas as urls(endpoints) deverão ser inseridos aqui
 */
function customParamsSerializer(params) {
  const parts = [];
  for (const key in params) {
    const values = Array.isArray(params[key]) ? params[key] : [params[key]];
    values.forEach((val) => {
      parts.push(`${key}=${val}`);
    });
  }
  return parts.join("&");
}

class BaseAPI {
  /** Métodos get */
  static async get(endpoint, params = {}) {
    const requestConfig = {
      params,
      paramsSerializer: customParamsSerializer,
    };
    return await api.get(endpoint, requestConfig);
  }

  /** Métodos post */
  static async post(endpoint, data = {}) {
    return await api.post(endpoint, data);
  }

  /** Método Put */
  static async put(endpoint, data = {}) {
    return await api.put(endpoint, data);
  }

  /** Método delete */
  static async delete(endpoint, data = {}) {
    return await api.delete(endpoint, { data });
  }

  /** Método Patch */
  static async patch(endpoint, data = {}) {
    return await api.patch(endpoint, data);
  }
}

/** URL de acordo ao protocolo, hostname e endpoint */
export const baseURL = () =>
  process.env.REACT_APP_API_URL || "http://localhost:8000/";

// Cria uma instancia de api
const api = axios.create({
  baseURL: baseURL(),
  // withCredentials: true,
  // headers: {
  //   "Access-Control-Request-Method": "POST",
  //   "Access-Control-Request-Headers": "Content-Type",
  // },
});
api.interceptors.request.use(
  (config) => {
    const token = StorageUtil.getItem(KEYS.TOKEN_KEY);
    config.headers = {
      "Content-Type": "application/json; charset=utf-8",
      accept: "*/*",
    };

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Intercepta os retornos.
// TODO fazer a lógica do refresh token.
api.interceptors.response.use(
  (response) => {
    const token = response.data?.token;
    if (token) {
      StorageUtil.setItem(KEYS.TOKEN_KEY, token);
      StorageUtil.setItem(KEYS.REMEMBER, true);
    }
    return response;
  },
  (error) => Promise.reject(error)
);

// Lista de todos os endpoints. Todos endpoints deverão ser inseridos aqui.
const VERSION = "/v1";
export const URLS = {
  VERSION,
  MFA: `${VERSION}/user/2fa-request/`,
  LOGIN: `${VERSION}/user/auth/`,
  FORGET_PASSWORD: `${VERSION}/user/password-reset/`,
  COMPANY: `${VERSION}/company/`,
  USER: `${VERSION}/user/`,
  ORGANIZATION: `${VERSION}/company/organization`,
  LOCKER: `${VERSION}/locker`,
  ALLOCATION: `${VERSION}/allocation`,
  PERMISSION: `${VERSION}/permission`,
};

export default BaseAPI;
