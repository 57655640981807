import AuthStore from "./AuthStore";
import AdministratorsStore from "./AdministratorsStore";
import SeparatorStore from "./SeparatorStore";
import CustomerStore from "./CustomerStore";
import MenuStore from "./MenuStore";
import OrganizationStore from "./OrganizationStore";
import LockerStore from "./LockerStore";
import AllocationStore from "./AllocationStore";
import PermissionStore from "./PermissionStore";
import CompanyFeaturesStore from "./CompanyFeaturesStore";

/**
 * Rootstore guarda a instancia das stores.
 * Assim a comunicação das stores é feita atraves da rootStore.
 *
 * https://mobx.js.org/best/store.html
 */

class RootStore {
  _mobxStores = null;

  constructor(
    serializedStores = {
      administratorsStore: {},
      separatorStore: {},
      customerStore: {},
      authStore: {},
      menuStore: {},
      lockerStore: {},
      allocationStore: {},
      permissionStore: {},
      CompanyFeaturesStore: {},
    }
  ) {
    if (this._mobxStores === null) {
      this.administratorsStore = new AdministratorsStore(this, {
        ...serializedStores.administratorsStore,
      });
      this.separatorStore = new SeparatorStore(this, {
        ...serializedStores.separatorStore,
      });
      this.customerStore = new CustomerStore(this, {
        ...serializedStores.customerStore,
      });

      this.authStore = new AuthStore(this, {
        ...serializedStores.authStore,
      });
      this.menuStore = new MenuStore(this, {
        ...serializedStores.menuStore,
      });
      this.organizationStore = new OrganizationStore(this, {
        ...serializedStores.organizationStore,
      });
      this.lockerStore = new LockerStore(this, {
        ...serializedStores.lockerStore,
      });
      this.allocationStore = new AllocationStore(this, {
        ...serializedStores.allocationStore,
      });
      this.permissionStore = new PermissionStore(this, {
        ...serializedStores.permissionStore,
      });
      this.companyFeaturesStore = new CompanyFeaturesStore(this, {
        ...serializedStores.companyFeaturesStore
      })
    }
  }

  get mobxStores() {
    return (this._mobxStores = {
      administratorsStore: this.administratorsStore,
      separatorStore: this.separatorStore,
      customerStore: this.customerStore,
      authStore: this.authStore,
      menuStore: this.menuStore,
      organizationStore: this.organizationStore,
      lockerStore: this.lockerStore,
      allocationStore: this.allocationStore,
      permissionStore: this.permissionStore,
      companyFeaturesStore: this.companyFeaturesStore,
    });
  }
}

let store = null;

export function initializeStores(props) {
  if (store === null) {
    store = new RootStore(props);
  }
  return store;
}

export default RootStore;
