import { extendObservable, decorate, action } from "mobx";

import AllocationModel from "../models/AllocationModel";
import AllocationAPI from "../services/AllocationAPI";

import ToastHelper, { STATUS_HELPER } from "../helpers/ToastHelper";
import { convertObjectToFilter } from "~/helpers/utils/ConvertObjectToFilter";
import { exportCSV } from "~/helpers/utils/ExportCSV";
import ContainerAllocationModel from "../models/ContainerAllocationModel";
import StatusModel from "../models/StatusModel";
import { setURLSearchParams } from "../helpers/utils/SetURLSearchParams";

/**Valores inicias de variaveis observadas */
const initValues = {
  allocation: undefined,
  allocations: [],
  reverses: [],
  statuses: [],
  loading: false,
  actionLoading: false,


  //Pagination Manager
  empty: false,
  page: 1,
  totalElements: undefined,
  totalPages: undefined,

  //Pagination Allocation In Locker
  emptyInLocker: false,
  pageInLocker: 1,
  totalElementsInLocker: undefined,
  totalPagesInLocker: undefined,
};
class AllocationStore {
  /**Constructor */
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, initValues);
    this.toastHelper = new ToastHelper();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  resetInLockerAllocationParams = () => {
    //Pagination Allocation In Locker
    this.emptyInLocker = false;
    this.pageInLocker = 1;
    this.totalElementsInLocker = undefined;
    this.totalPagesInLocker = undefined;
  };

  /**Reset values */
  reset() {
    this.allocation = undefined;
    this.loading = false;
    this.actionLoading = false;
    this.reverses = [];
    this.allocations = [];

    this.statuses = [];

    // Pagination
    this.empty = false;
    this.page = 1;
    this.totalElements = undefined;
    this.totalPages = undefined;

    this.resetInLockerAllocationParams();
  }

  emptyAllocation() {
    this.allocation = new AllocationModel({});
  }

  onChange(prop, value) {
    if (!this.allocation) this.allocation = new AllocationModel();

    switch (prop) {
      case "status":
        this.allocation[prop] = { code: value.value, name: value.label };
        break;

      case "locker":
        this.allocation[prop] = value.value;
        break;

      case "customer":
        this.allocation[prop] = value.value;
        this.allocation["organization"] = value.value.organization;
        break;

      default:
        console.log('prop', prop )
        if (!prop.includes('observations-'))
          this.allocation[prop] = value;
    }
  }

  onChangeObservation(value, container) {
    const uuidToAdd = container.uuid;
    const containerIndex =
      this.allocation.containerAllocations.length > 0
        ? this.allocation.containerAllocations.findIndex(
            (item) => item.uuid === uuidToAdd
          )
        : -1;

    if(containerIndex !== -1) {
      this.allocation.containerAllocations[containerIndex].observations = value;
    }
  }

  async getList(params) {
    this.loading = true;

    params = {
      page: params?.page ?? this.page,
      filter: params?.filter ?? '',
    };
    setURLSearchParams(params.page, params.filter)


    const response = await AllocationAPI.list({page: params.page, filter:convertObjectToFilter(params.filter)});
    this.loading = false;
    if (!response.error) {
      this.allocations = response.content.map(
        (administrator) => new AllocationModel(administrator)
      );
      this.empty = response.empty;
      this.page = response.page;
      this.totalPages = response.totalPages;
      this.totalElements = response.totalElements;

      return this.administrators;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async getAllocationByUuid(uuid) {
    this.loading = true;
    const response = await AllocationAPI.getAllocationByUuid(uuid);
    this.loading = false;

    if (!response.error) {
      this.allocation = new AllocationModel(response);
      return this.allocation;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

  async getAllocationByCodeAndLockerUuid(code, uuidLocker) {
    this.loading = true;
    const response = await AllocationAPI.getAllocationByCodeAndLockerUuid(
      code,
      uuidLocker
    );
    if (!response.error) {
      this.allocation = new AllocationModel(response);
      return this.allocation;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getLockerReverseList(uuidLocker) {
    this.loading = true;
    const response = await AllocationAPI.getLockerReverseList(uuidLocker);
    if (!response.error) {
      this.reverses = response.content.map((i) => new AllocationModel(i));
      return this.reverses;
    }
    this.loading = false;
  }

  async getInLockerList(uuidLocker, params) {
    this.loading = true;

    params = {
      page: params?.page ?? this.pageInLocker,
    };

    const response = await AllocationAPI.getInLockerList(uuidLocker, params);
    if (!response.error) {
      this.allocations = response.content.map((i) => new AllocationModel(i));

      this.emptyInLocker = response.empty;
      this.pageInLocker = response.page;
      this.totalPagesInLocker = response.totalPages;
      this.totalElementsInLocker = response.totalElements;

      return this.allocations;
    }
    this.loading = false;
  }

  clearSettedContainers() {
    if (!this.allocation) {
      this.allocation = new AllocationModel();
    }

    this.allocation.containerAllocations = [];

    return this.selectedContainers;
  }

  setContainers(container) {
    if (!this.allocation) {
      const test = new AllocationModel();
      console.log("allocation ", test);
      this.allocation = test;
    }

    const uuidToAdd = container.uuid;

    // Checa se existe uuid do container na lista
    const existingContainerIndex =
      this.allocation.containerAllocations.length > 0
        ? this.allocation.containerAllocations.findIndex(
            (item) => item.container.uuid === uuidToAdd
          )
        : -1;

    if (existingContainerIndex !== -1) {
      // Caso existir remove ele da lista
      this.allocation.containerAllocations.splice(existingContainerIndex, 1);
    } else {
      // Caso nao adiciona na lista
      // container.isAvailable = false;
      const containerAllocation = new ContainerAllocationModel({
        inLocker: false,
        container: container,
      });
      this.allocation.containerAllocations.push(containerAllocation);
    }

    return this.selectedContainers;
  }

  get selectedContainers() {
    return this.allocation.containerAllocations.map((i) => i.container.uuid);
  }

  /**Busca configurações de empresa */
  async onCreate() {
    this.loading = true;
    this.allocation.status = new StatusModel({ code: "NEW", name: "Novo" });
    const response = await AllocationAPI.create(this.allocation);
    this.loading = false;

    if (!response.error) {
      this.allocation = new AllocationModel(response);
      return this.allocation;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

  /**Busca configurações de empresa */
  async onUpdate(uuid) {
    this.loading = true;
    const response = await AllocationAPI.update(uuid, this.allocation);
    if (!response.error) {
      this.allocation = new AllocationModel(response);
      return this.allocation;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Deleta alocacao */
  async onDelete(uuid) {
    this.loading = true;
    const response = await AllocationAPI.delete(uuid);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Alocação excluída com sucesso"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Busca configurações de empresa */
  async allocate(data) {
    this.loading = true;
    const response = await AllocationAPI.allocate(this.allocation.uuid, data);
    if (!response.error) {
      this.allocation = new AllocationModel(response);
      return this.allocation;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca configurações de empresa */
  async allocationFinished() {
    this.loading = true;
    const response = await AllocationAPI.allocationFinished(
      this.allocation.uuid,
      this.allocation
    );
    if (!response.error) {
      this.allocation = new AllocationModel(response);
      return this.allocation;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca configurações de empresa */
  async deallocate(data) {
    this.loading = true;
    const response = await AllocationAPI.deallocate(this.allocation.uuid, data);
    if (!response.error) {
      this.allocation = new AllocationModel(response);
      return this.allocation;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca configurações de empresa */
  async reverse(data) {
    this.loading = true;
    const response = await AllocationAPI.reverse(this.allocation.uuid, data);
    if (!response.error) {
      this.allocation = new AllocationModel(response);
      return this.allocation;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getSelectAllocationStatuses() {
    const response = await AllocationAPI.getAllocationStatuses();

    if (response.error) {
      this.statuses = [];
      return;
    }

    this.statuses = response.map((status) => ({
      value: status.code,
      label: status.name,
    }));
  }

  async export(params) {
    this.loading = true;

    params = {
      filter: convertObjectToFilter(params?.filter),
    };

    const response = await AllocationAPI.export(params);
    this.loading = false;
    if (!response.error) {
      exportCSV(response, "alocações");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Envia mensagem de nova alocacao*/
  async sendMessageNewAllocation() {
    this.actionLoading = true;
    const response = await AllocationAPI.sendMessageNewAllocation(
      this.allocation.uuid
    );
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Mensagem enviada com sucesso!"
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.actionLoading = false;
  }

  /**Envia mensagem de nova alocacao*/
  async sendEmailNewAllocation() {
    this.actionLoading = true;
    const response = await AllocationAPI.sendEmailNewAllocation(
      this.allocation.uuid
    );
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "E-mail enviado com sucesso!"
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.actionLoading = false;
  }
}
decorate(AllocationStore, {
  getFeatures: action,
});

export default AllocationStore;
