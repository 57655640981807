import { extendObservable, decorate, action } from "mobx";

import CustomerAPI from "../services/CustomerAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import CustomerModel from "~/models/CustomerModel";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";

import { convertObjectToFilter } from "~/helpers/utils/ConvertObjectToFilter";
import { exportCSV } from "~/helpers/utils/ExportCSV";
import { setURLSearchParams } from "../helpers/utils/SetURLSearchParams";

const initValues = {
  customer: undefined,
  customers: [],
  customersAsyncSelect: [],
  empty: false,
  page: 1,
  totalElements: undefined,
  totalPages: undefined,
  loading: false,
};

class CustomerStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, initValues);
  }

  reset() {
    this.customer = undefined;
    this.customers = [];
    this.customersAsyncSelect = [];
    this.empty = false;
    this.page = 1;
    this.totalElements = undefined;
    this.totalPages = undefined;
    this.loading = false;
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isStaff() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN" || currentAuth === "STOOM";
  }

  emptyCustomer() {
    this.customer = new CustomerModel({});
  }

  onChange(prop, value) {
    if (!this.customer) this.customer = new CustomerModel();

    switch (prop) {
      case "organization":
        this.customer.organization = value.value;
        break;
      case "phone":
        this.customer[prop] = value.replace(/\D/g, "");
        break;

      default:
        this.customer[prop] = value;
    }
  }

  /**
   * Busca todas as informações de um usuario separador.
   * @param  {string} uuid
   */
  async get(uuid) {
    this.loading = true;

    if (!uuid) uuid = StorageUtil.getItem(KEYS.USER_KEY);

    const response = await CustomerAPI.getCustomer(uuid);
    this.loading = false;
    if (!response.error) {
      this.customer = new CustomerModel(response);
      return this.customer;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Busca todas as informações de um usuario separador.
   * @param  {string} uuid
   */
  async getList(params) {
    this.loading = true;

    params = {
      page: params?.page ?? this.page,
      filter: params?.filter ?? '',
    };
    setURLSearchParams(params.page, params.filter)

    const response = await CustomerAPI.list({page: params.page, filter:convertObjectToFilter(params.filter)});
    this.loading = false;
    if (!response.error) {
      this.customers = response.content.map(
        (customer) => new CustomerModel(customer)
      );
      this.page = response.page;
      this.totalPages = response.totalPages;
      this.totalElements = response.totalElements;

      return this.customers;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }
  /**
   * Cadastra um usuario separador.
   */
  async onCreate() {
    this.loading = true;

    const response = await CustomerAPI.createCustomer(this.customer);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Cliente cadastrado com sucesso"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Atualiza dados de um usuario separador.
   * @param  {string} uuid
   */
  async onUpdate(uuid) {
    this.loading = true;

    this.customer.email =
      this.customer.email != "" ? this.customer.email : null;

    const response = await CustomerAPI.updateCustomer(uuid, this.customer);

    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Cliente atualizado com sucesso"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Busca todas as informações de um usuario separador.
   * @param  {string} uuid
   */
  async onDelete(uuid) {
    this.loading = true;

    const response = await CustomerAPI.deleteCustomer(uuid);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Cliente excluído com sucesso"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async getCustomersAsyncSelect(name) {
    const filter = `fullName__icontains=${name}`;
    const response = await CustomerAPI.list({ filter });

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response.content;
  }

  async export(params) {
    this.loading = true;

    params = {
      filter: convertObjectToFilter(params?.filter),
    };

    const response = await CustomerAPI.export(params);
    this.loading = false;
    if (!response.error) {
      exportCSV(response, "clientes");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async import(data) {
    this.loading = true;

    const response = await CustomerAPI.import(data);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Importação realizada com sucesso!"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }
}
decorate(CustomerStore, {
  get: action,
  getList: action,
  onDelete: action,
});

export default CustomerStore;
