import { extendObservable, decorate, action } from "mobx";

import PermissionAPI from "../services/PermissionAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import PermissionModel from "~/models/PermissionModel";

const initValues = {
  permission: undefined,
  ownPermissions: [],
  permissions: [],
  permissionsAsyncSelect: [],
  loading: false,
};

class PermissionStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, initValues);
  }

  reset() {
    this.permission = undefined;
    this.permissions = [];
    this.ownPermissions = [];
    this.permissionsAsyncSelect = [];
    this.loading = false;
  }

  emptyPermission() {
    this.permission = new PermissionModel({});
  }

  pushPermission(permissionToPush) {
    this.permissions.push(permissionToPush);
  }

  removePermission(permissionToRemove) {
    this.permissions = this.permissions.filter(
      (i) => i.codename !== permissionToRemove.codename
    );
  }

  /**
   * Busca todas as permissões disponiveis.
   * @param  {string} uuid
   */
  async getAsyncSelectList(name) {
    this.loading = true;
    const params = { filter: `name__icontains=${name}` };
    const response = await PermissionAPI.getList(params);
    this.loading = false;
    if (!response.error) {
      this.permissionsAsyncSelect = response.map(
        (permission) => new PermissionModel(permission)
      );
      return this.permissionsAsyncSelect;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Busca todas as permissoes de um usuario.
   * @param  {string} uuid
   */
  async getListUserPermissions(uuid, params) {
    this.loading = true;

    const response = await PermissionAPI.getListUserPermissions(uuid, params);
    this.loading = false;
    if (!response.error) {
      this.permissions = response.map(
        (permission) => new PermissionModel(permission)
      );

      return this.permissions;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Busca todas as permissoes de um usuario.
   * @param  {string} uuid
   * @param {string} data
   */
  async setUserPermissions(uuid, data) {
    this.loading = true;

    const response = await PermissionAPI.setUserPermissions(uuid, data);
    this.loading = false;
    if (!response.error) {
      this.permissions = response.map(
        (permission) => new PermissionModel(permission)
      );
      return this.permissions;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async getOwnPermissions() {
    this.loading = true;

    const response = await PermissionAPI.getOwnUserPermissions();
    this.loading = false;
    if (!response.error) {
      this.ownPermissions = response.map(
        (permission) => new PermissionModel(permission)
      );
      return this.permissions;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  validatePermissions(permissions = []) {
    const result = this.ownPermissions
      ?.map((i) => i.codename)
      .some((i) => permissions.includes(i));
    return result;
  }
}
decorate(PermissionStore, {
  get: action,
  getList: action,
  onUpdate: action,
});

export default PermissionStore;
