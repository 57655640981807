import { extendObservable, decorate, action } from "mobx";

import OrganizationModel from "~/models/OrganizationModel";
import UserModel from "~/models/UserModel";
import OrganizationAPI from "../services/OrganizationAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import { convertObjectToFilter } from "~/helpers/utils/ConvertObjectToFilter";
import { exportCSV } from "~/helpers/utils/ExportCSV";
import { setURLSearchParams } from "../helpers/utils/SetURLSearchParams";

/**Valores inicias de variaveis observadas */
const initValues = {
  organization: undefined,
  organizations: [],
  organizationsListSelect: undefined,
  organizationCustomers: [],
  finalNodes: [],
  loading: false,
  empty: false,
  page: 1,
  totalElements: undefined,
  totalPages: undefined,
};
class OrganizationStore {
  // totalPages = 0;
  //page = 0;
  //size = 20;
  /**Constructor */
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, initValues);
    this.toastHelper = new ToastHelper();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Reset values */
  reset() {
    this.organization = undefined;
    this.organizations = [];
    this.organizationsListSelect = undefined;
    this.organizationCustomers = [];
    this.finalNodes = [];
    this.loading = false;
    this.empty = false;
    this.page = 1;
    this.totalElements = undefined;
    this.totalPages = undefined;
  }

  /**Atualiza propriedades da categoria */
  onChange(prop, value) {
    if (!this.organization) this.organization = new OrganizationModel();

    switch (prop) {
      case "street":
      case "number":
      case "complement":
      case "district":
      case "city":
      case "country":
      case "zipCode":
        this.organization.address[prop] = value;
        break;

      case "state":
        this.organization.address[prop] = value.value;
        break;

      default:
        this.organization[prop] = value;
    }
  }

  /**Cria nova categoria com dados padrão */
  emptyOrganization() {
    this.organization = new OrganizationModel({});
  }

  /**Salva nova categoria. */
  async onCreate() {
    this.loading = true;

    let data = JSON.stringify(this.organization);
    const response = await OrganizationAPI.save(data);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.emptyOrganization();
      await this.getTree();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Categoria incluida com sucesso"
      );
    }
    return response;
  }

  /**Atualiza categoria categoria. */
  async onUpdate() {
    this.loading = true;
    const organization = new OrganizationModel(this.organization);
    const data = JSON.stringify(organization);
    const response = await OrganizationAPI.update(this.organization.uuid, data);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      await this.getTree();
      this.organization = new OrganizationModel(response);
      this.toastHelper.notify(STATUS_HELPER.INFO, "Dados atualizados");
    }
    return response;
  }

  /**Busca categorias em arvores*/
  async getOwnOrganization() {
    this.loading = true;
    const response = await OrganizationAPI.getOwn();
    this.loading = false;
    if (!response.error) {
      this.organizations = new OrganizationModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  async getFinalNodes(uuid) {
    this.loading = true;
    const response = await OrganizationAPI.getFinalNodes(uuid);
    this.loading = false;
    if (!response.error) {
      this.finalNodes = response.content.map(
        (cat) => new OrganizationModel(cat)
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  async getOrganizationCustomers(uuid) {
    this.loading = true;
    const response = await OrganizationAPI.getOrganizationCustomers(uuid);
    this.loading = false;
    if (!response.error) {
      this.organizationCustomers = response.map((cat) => new UserModel(cat));
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  async getTree(params) {
    this.loading = true;
    const response = await OrganizationAPI.getTree(params);
    this.loading = false;
    if (!response.error) {
      this.organizations = response.content.map(
        (cat) => new OrganizationModel(cat)
      );
      this.page = response.page;
      this.totalPages = response.totalPages;
      this.totalElements = response.totalElements;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  async getList(params) {
    this.loading = true;

    params = {
      page: params?.page ?? this.page,
      filter: params?.filter ?? '',
    };
    setURLSearchParams(params.page, params.filter)

    const response = await OrganizationAPI.getList({page: params.page, filter:convertObjectToFilter(params.filter)});
    this.loading = false;
    if (!response.error) {
      this.organizations = response.content.map(
        (cat) => new OrganizationModel(cat)
      );
      this.page = response.page;
      this.totalPages = response.totalPages;
      this.totalElements = response.totalElements;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  /**Busca categoria por uuid */
  async get(uuid) {
    this.loading = true;
    const response = await OrganizationAPI.get(uuid);
    if (!response.error) {
      this.organization = new OrganizationModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Deleta categoria */
  async delete(uuid) {
    this.loading = true;
    const response = await OrganizationAPI.delete(uuid);
    if (!response.error) this.toastHelper.notify("info", "Categoria removida");
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Retorna lista de categorias para uso no select */
  getTreeSelect(excludes = {}) {
    if (!excludes) return;

    const localFilter = (organization) => {
      const hasChildren = organization.childrenOrganization.length > 0;
      if (hasChildren) {
        organization.childrenOrganization =
          organization.childrenOrganization.filter(localFilter);
      }
      return !excludes.some((cat) => cat.uuid === organization.uuid);
    };

    if (Array.isArray(excludes)) {
      return this.organizations.filter(localFilter);
    }
    return this.organizations.filter((cat) => cat.uuid !== excludes.uuid);
    //        return list.map(mCat => ({ value: mCat.uuid, label: mCat.organizationName }));
  }

  genarateSelectOptions(items, parentPath) {
    let options = [];
    items.forEach((item) => {
      const organizationName =
        (parentPath ? parentPath + "/" : "") + item.organizationName;
      options = [...options, { value: item.uuid, label: organizationName }];
      if (item.childrenOrganization?.length > 0)
        options = [
          ...options,
          ...this.genarateSelectOptions(
            item.childrenOrganization,
            organizationName
          ),
        ];
    });

    return options;
  }

  async getTreeOrganizationSelect() {
    this.loading = true;
    const response = await OrganizationAPI.getTree();
    this.organizationsListSelect = this.genarateSelectOptions(response);
    this.loading = false;
  }

  async getOrganizationsAsyncSelect(name) {
    const filter = `fullName__icontains=${name}`;
    const response = await OrganizationAPI.getList({ filter });

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response.content;
  }

  async export(params) {
    this.loading = true;

    params = {
      filter: convertObjectToFilter(params?.filter),
    };

    const response = await OrganizationAPI.export(params);
    this.loading = false;
    if (!response.error) {
      exportCSV(response, "organizações");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async import(data) {
    this.loading = true;

    const response = await OrganizationAPI.import(data);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Importação realizada com sucesso!"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }
}
decorate(OrganizationStore, {
  get: action,
  getOwn: action,
  save: action,
  update: action,
  delete: action,
  getTree: action,
  getFinalNodes: action,
});

export default OrganizationStore;
