import { extendObservable, decorate, action } from "mobx";

import AdministratorsAPI from "../services/AdministratorsAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import AdministratorModel from "~/models/AdministratorModel";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";
import { convertObjectToFilter } from "~/helpers/utils/ConvertObjectToFilter";
import { exportCSV } from "~/helpers/utils/ExportCSV";
import { setURLSearchParams } from "../helpers/utils/SetURLSearchParams";

const initValues = {
  administrator: undefined,
  administrators: [],
  empty: false,
  page: 1,
  totalElements: undefined,
  totalPages: undefined,
  loading: false,
};

class AdministratorsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, initValues);
  }

  reset() {
    this.administrator = undefined;
    this.administrators = [];
    this.empty = false;
    this.page = 1;
    this.totalElements = undefined;
    this.totalPages = undefined;
    this.loading = false;
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isStaff() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN" || currentAuth === "STOOM";
  }

  emptyAdministrator() {
    this.administrator = new AdministratorModel({});
  }

  onChange(prop, value) {
    if (!this.administrator) this.administrator = new AdministratorModel();

    switch (prop) {
      case "phone":
        this.administrator[prop] = value.replace(/\D/g, "");
        break;
      default:
        this.administrator[prop] = value;
    }
  }

  /**
   * Busca todas as informações de um usuario administrador.
   * @param  {string} uuid
   */
  async get(uuid) {
    this.loading = true;

    if (!uuid) uuid = StorageUtil.getItem(KEYS.USER_KEY);

    const response = await AdministratorsAPI.getUserAdmin(uuid);
    this.loading = false;
    if (!response.error) {
      this.administrator = new AdministratorModel(response);
      return this.administrator;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Busca todas as informações de um usuario administrador.
   * @param  {string} uuid
   */
  async getList(params = {}) {
    this.loading = true;

    params = {
      page: params?.page ?? this.page,
      filter: params?.filter ?? '',
    };
    setURLSearchParams(params.page, params.filter)

    const response = await AdministratorsAPI.list({page: params.page, filter:convertObjectToFilter(params.filter)});
    this.loading = false;
    if (!response.error) {
      this.administrators = response.content.map(
        (administrator) => new AdministratorModel(administrator)
      );
      this.empty = response.empty;
      this.page = response.page;
      this.totalPages = response.totalPages;
      this.totalElements = response.totalElements;

      return this.administrators;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async export(params) {
    this.loading = true;

    params = {
      filter: convertObjectToFilter(params?.filter),
    };

    const response = await AdministratorsAPI.export(params);
    this.loading = false;
    if (!response.error) {
      exportCSV(response, "administradores");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Cadastra um usuario administrador.
   */
  async onCreate() {
    this.loading = true;

    const response = await AdministratorsAPI.createAdministrator(
      this.administrator
    );
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Administrador cadastrado com sucesso"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Atualiza dados de um usuario administrador.
   * @param  {string} uuid
   */
  async onUpdate(uuid) {
    this.loading = true;

    const response = await AdministratorsAPI.updateAdministrator(
      uuid,
      this.administrator
    );
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Administrador atualizado com sucesso"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Busca todas as informações de um usuario administrador.
   * @param  {string} uuid
   */
  async onDelete(uuid) {
    this.loading = true;

    const response = await AdministratorsAPI.deleteAdministrator(uuid);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Administrador excluído com sucesso"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }
}
decorate(AdministratorsStore, {
  get: action,
  getList: action,
  onDelete: action,
});

export default AdministratorsStore;
